document.addEventListener('DOMContentLoaded', function () {
    const tables = document.querySelectorAll('.body table');

    tables.forEach(table => {
        let wrapper = document.createElement('div');
        wrapper.classList.add('table-wrapper');
        table.parentNode.insertBefore(wrapper, table);
        wrapper.appendChild(table);
        table.setAttribute('tabindex', '0');
    });
});